import http from "../utils/http"

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/personal-account/api/v1`

const getCustomerById = (id: number) => {
  return http.get(`${BASE_URL}/customer/${id}`)
}

export default {
  getCustomerById,
}
