import React, {lazy, Suspense} from "react"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import {useUserContext} from "../context/userContext"
import NotFoundPage from "../../pages/notFound"
import MainLayout from "../layouts/MainLayout"
import IndexPage from "../../pages/index"
import {getListOfAvailablePermissions} from "../utils/helpers"
import {Permission, Resource} from "../interfaces/authInterfaces"
import {ErrorBoundary} from "react-error-boundary"
import ErrorLayout from "../layouts/ErrorLayout"
import OrderIndexPage from "../../pages/orders/[orderId]"
import MotivationOrdersPage from "../../pages/motivation/orders"
import {SignedOffer} from "../../pages/signed-offer";
import {SingedOfferDetail} from "../../pages/signed-offer/[customer_id]";
import {AppRoutes} from "../constants/routes";

const PrivateRoute = ({permission}: {permission: Permission; permissionName: string}) => {
  if (permission.operation?.operation_name === "read") {
    const dynamicRoutes = permission.resource?.resource_name.match(/\[[^\]]*]/g)

    let ComponentToRender

    if (dynamicRoutes && permission.resource) {
      const neighbourPathsOfDynamicRoutes = getNeighbourPathsOfDynamicRoutes(dynamicRoutes, permission.resource)
      const pathEnding: string = neighbourPathsOfDynamicRoutes[neighbourPathsOfDynamicRoutes.length - 1].right
        ? "/index.tsx"
        : ".tsx"
      let path: string = ""

      neighbourPathsOfDynamicRoutes.forEach((neighbourPathOfDynamicRoute, idx) => {
        path += neighbourPathOfDynamicRoute.left + dynamicRoutes[idx] + neighbourPathOfDynamicRoute.right
      })

      // remove double slashes
      path = path.replace(/[/]{2}/g, "/")

      ComponentToRender = lazy(() => import("../../pages/" + path + pathEnding))
    } else {
      ComponentToRender = lazy(() => import("../../pages/" + permission.resource?.resource_name + "/index.tsx"))
    }
    return (
      <Suspense fallback={<MainLayout />}>
        <ErrorBoundary FallbackComponent={ErrorLayout}>
          <ComponentToRender />
        </ErrorBoundary>
      </Suspense>
    )
  }
  return <NotFoundPage />
}

const Router: React.FC = () => {
  const {userInfo} = useUserContext()

  const permissions = getListOfAvailablePermissions(userInfo?.roles || [])

  const RouteList = permissions.map((permission, index) => {
    const dynamicRoutes = permission.resource?.resource_name.match(/\[[^\]]*]/g)

    let path: string = ""
    if (dynamicRoutes && permission.resource) {
      const neighbourPathsOfDynamicRoutes = getNeighbourPathsOfDynamicRoutes(dynamicRoutes, permission.resource)
      const reactDynamicRoutes = dynamicRoutes.map((dynamicRoute) => ":" + dynamicRoute.replace(/[\[\]']+/g, ""))

      neighbourPathsOfDynamicRoutes.forEach((neighbourPathOfDynamicRoute, idx) => {
        path += neighbourPathOfDynamicRoute.left + reactDynamicRoutes[idx] + neighbourPathOfDynamicRoute.right
      })

      if (
        ["read_future_bonusCommandment_createBonusGroup", "read_future_bonusCommandment_editBonusGroup"].includes(
          permission.permission_name,
        )
      ) {
        // console.log('neighbourPathsOfDynamicRoutes',neighbourPathsOfDynamicRoutes)
      }

      // remove double slashes
      path = path.replace(/[/]{2}/g, "/")
    } else {
      path = permission.resource?.resource_name ?? ""
    }

    return (
      <Route
        key={index}
        path={path}
        element={<PrivateRoute key={index} permission={permission} permissionName={permission.permission_name} />}
      />
    )
  })

  return (
    <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route index element={<IndexPage />} />
          <Route path="/orders/:orderId" element={<OrderIndexPage />} />
          <Route path={AppRoutes.signedOffer} element={<SignedOffer />} />
          <Route path={`${AppRoutes.signedOfferDetails}/:customer_id`} element={<SingedOfferDetail />} />
          {RouteList}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </MainLayout>
    </BrowserRouter>
  )
}

function getNeighbourPathsOfDynamicRoutes(
  dynamicRoutes: RegExpMatchArray,
  permissionResource: Resource,
): Array<{left: string; right: string}> {
  const indexesOfDynamicRoutes: Array<{start: number; end: number}> = []
  for (const dynamicRoute of dynamicRoutes) {
    const start = permissionResource.resource_name.indexOf(dynamicRoute)
    indexesOfDynamicRoutes.push({start, end: start + dynamicRoute.length})
  }

  const neighbourPathsOfDynamicRoutes: Array<{left: string; right: string}> = []
  indexesOfDynamicRoutes.forEach((indexOfDynamicRoute, idx, array) => {
    let left: string = "",
      right: string = ""

    // first iteration
    if (idx === 0) left = permissionResource.resource_name.slice(0, indexOfDynamicRoute.start) ?? ""

    // not first iteration
    if (array[idx - 1])
      left = permissionResource.resource_name.slice(array[idx - 1].end, indexOfDynamicRoute.start) ?? ""

    // not last iteration
    if (idx !== array.length - 1)
      right = permissionResource.resource_name.slice(indexOfDynamicRoute.end, array[idx + 1].start) ?? ""
    else right = permissionResource.resource_name.slice(indexOfDynamicRoute.end) ?? ""

    neighbourPathsOfDynamicRoutes.push({left, right})
  })

  return neighbourPathsOfDynamicRoutes
}

export default Router
