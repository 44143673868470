import {SaleService} from "../../../../../app/services/SaleService"
import {IPersonalAccountCustomer} from "../../../../../app/interfaces/personalAccountInterfaces"
import {PublicOffer} from "../../../../../app/interfaces/salesInterfaces"

export const fetchOfferAgreementData = (page: number, size: number, customer: IPersonalAccountCustomer) => {
  return SaleService.getPublicOffers(page, size).then((res) => {
    if (res.data.status) {
      res.data.result.items = customer.offer_histories.map((history) => {
        const offer = res.data.result.items?.find(offer => offer.public_offer_id === history?.public_offer_id) as PublicOffer
        return {
          ...offer,
          agreement_date: new Date(history.created_at.split("Z")[0]).toLocaleString(),
        }
      })
    }
    return res
  })
}
