import {useNavigate, useParams} from "react-router-dom"
import Heading from "../../../app/components/Heading/Heading"
import React, {useEffect, useState} from "react"
import {AppRoutes} from "../../../app/constants/routes"
import s from "./SignedOfferDetail.module.scss"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {SignedOfferDetailsCustomer} from "./components/SignedOfferDetailsCustomer/SignedOfferDetailsCustomer"
import {OfferAgreementTable} from "./components/OfferAgreementTable/OfferAgreementTable"
import PersonalAccountService from "../../../app/services/PersonalAccountService"
import {IPersonalAccountCustomer} from "../../../app/interfaces/personalAccountInterfaces"

export const SingedOfferDetail = () => {
  const {customer_id} = useParams()
  const navigate = useNavigate()
  const [fetching, setFetching] = useState(false)
  const [customer, setCustomer] = useState<IPersonalAccountCustomer | null>(null)

  useEffect(() => {
    if (!customer_id) return
    const customerId = parseInt(customer_id)
    if (isNaN(customerId)) return
    setFetching(true)
    PersonalAccountService.getCustomerById(customerId)
      .then((res) => {
        if (res.data.status) {
          setCustomer(res.data.result as IPersonalAccountCustomer)
        }
      })
      .finally(() => setFetching(false))
    return () => {
      setFetching(false)
    }
  }, [])

  if (!customer_id) return <></>
  const customerId = parseInt(customer_id)
  if (isNaN(customerId)) return <></>
  return (
    <div>
      <button onClick={() => navigate(AppRoutes.signedOffer)} className={s.goBack}>
        <ArrowBackIcon /> Назад
      </button>
      <Heading>Детали</Heading>
      {!!customer && <SignedOfferDetailsCustomer customer={customer}/>}
      {!!customer && <OfferAgreementTable customer={customer} />}
    </div>
  )
}
