import Heading from "../../app/components/Heading/Heading"
import React from "react"
import Table from "../../app/components/Table/Table"
import {UsersService} from "../../app/services/UsersService"
import {AppRoutes} from "../../app/constants/routes"
import {SaleService} from "../../app/services/SaleService"
import {OrderControlService} from "../../app/services/OrderControlService"

const filter = [
  {
    title: "Поиск клиентов по ИИН/номеру телефона/ФИО",
    size: 12,
    name: "search_line",
  },
]

export const SignedOffer = () => {
  const rows = [
    {
      title: "customer_id",
      value: "customer_id",
      width: "10%",
    },
    {
      title: "ФИО",
      value: "full_name",
      width: "20%",
    },
    {
      title: "Номер телефона",
      value: "phone",
      width: 150,
    },
    {
      title: "ИИН",
      value: "iin",
    },
    {
      title: "Действия",
      type: "link",
      action: {
        label: "Перейти",
        startIcon: "visibility",
        href: (item: any) => `/${"signed-offer"}/${item.customer_id}`,
      },
      width: 150,
    },
  ]

  return (
    <div>
      <Heading>Подписанные оферты</Heading>
      <Table rows={rows} fetchUrl={OrderControlService.getCustomerList} filter={filter} />
    </div>
  )
}
