import s from "./OfferAgreementTable.module.scss"
import Table from "../../../../../app/components/Table/Table"
import React, {FC} from "react"
import {SaleService} from "../../../../../app/services/SaleService"
import {fetchOfferAgreementData} from "./fetchOfferAgreementData"
import {IPersonalAccountCustomer} from "../../../../../app/interfaces/personalAccountInterfaces"
import {TableRowProps} from "../../../../../app/interfaces/layoutInterfaces"

export const OfferAgreementTable: FC<{customer: IPersonalAccountCustomer}> = ({customer}) => {
  const rows: TableRowProps[] = [
    {
      title: "ID оферты",
      value: "public_offer_id",
      width: "20%",
    },
    {
      title: "Дата и время согласия",
      value: "agreement_date",
      width: "20%",
    },
    {
      title: "Наименование оферты",
      value: "file_name",
      width: "20%",
    },
    {
      title: "Дата начала",
      value: "date_from",
    },
    {
      title: "Дата окончания",
      value: "date_to",
    },
    {
      title: "Действия",
      type: "link",
      action: {
        label: "Посмотреть",
        startIcon: "visibility",
        onClickAction: (item) => {
          window.open(`${process.env.REACT_APP_BASE_API_MEDIA_URL}/${item.file_path}`, "_blank")
        },
      },
      width: 150,
    },
  ]

  return (
    <div className={s.container}>
      <div className={s.title}>Согласие с офертой</div>
      {!!customer && (
        <Table rows={rows} fetchUrl={(p, s) => fetchOfferAgreementData(p, s, customer)} disablePagination />
      )}
    </div>
  )
}
