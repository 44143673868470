import React, {useEffect, useRef, useState} from "react"
import {Icon, TablePagination, Grid, TextField} from "@mui/material"
import styles from "./styles.module.scss"
import Button from "../Button/Button"
import {TableFilterAsyncSearch, TableProps} from "../../interfaces/layoutInterfaces"
import {useSearchParams} from "react-router-dom"
import {Alert} from "../../utils/alert"
import {convertArrayToSelectOptions} from "../../utils/helpers"
import {TableFilter} from "./TableFilter"
import {TableContent} from "./TableContent"
import {UsersService} from "../../services/UsersService"
import {AxiosError} from "axios"
import requestData from "../../utils/requestData"

import {
  DateTimePicker as MuiDateTimePicker,
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import ruLocale from "date-fns/locale/ru"

const Table: React.FC<TableProps> = ({
  rows,
  fetchUrl,
  filter,
  refresh,
  onUpdate,
  filterOpened,
  paginationItems,
  minWidth,
  filterByDate,
  filterByDateTime,
  getTableResults,
  disablePagination,
  param,
  checkboxSelecting,
  onCheckboxSelected,
  checkedValidationFunction,
  onCheckboxSelectAll,
  allCheckedValidationFunction,
  onChangePage,
  getFilterObject,
}) => {
  const [filterResults, setFilterResults] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [paginationData, setPaginationData] = useState<{
    count: number
    page: number
    perPage: number | "all"
  }>({
    count: 0,
    page: 0,
    perPage: paginationItems ? paginationItems[0] : 25,
  })

  const [showFilter, setShowFilter] = useState<boolean>(filterOpened || false)
  const [filterData, setFilterData] = useState<Record<string, string | boolean | Date | any[] | null | undefined>>({})
  const [isParamsParsedFromURL, setIsParamsParsedFromURL] = useState<boolean>(false)
  const filterSearchTimeout = useRef<any>()
  const [checkboxes, setCheckboxes] = useState<any>([])

  const handleCheckboxes = (id: number, name: string, value: boolean) => {
    console.log(name, value)
    setCheckboxes((prev: any) => {
      return prev.map((el: any) => {
        if (el.id === id) {
          return {
            ...el,
            active: value,
          }
        }
        return el
      })
    })
    console.log(
      checkboxes.map((el: any) => {
        if (el.active) {
          return {status: el.id}
        }
      }),
    )
    // setFilterData({...filterData, s)
    // fetchData(paginationData.page + 1, paginationData.perPage, {
    //   ...filterData,
    //   status: id
    // })
    // @ts-ignore
    // refreshSearchParams(name, status: id)
  }

  useEffect(() => {
    // Здесь формируется фильтр и пагинация с URL параметров
    let paginationParams: any = {}
    let filterParams: any = {}
    searchParams.forEach((value, key) => {
      if (key !== "page" && key !== "perPage" && key !== "count") {
        filterParams = {...filterParams, [key]: value}
      } else {
        paginationParams = {...paginationParams, [key]: value}
      }
    })
    setFilterData(filterParams)
    getFilterObject && getFilterObject(filterParams)
    setPaginationData({
      count: parseInt(paginationParams.count) || 0,
      page: paginationParams.page ? parseInt(paginationParams.page) - 1 : 0,
      perPage: parseInt(paginationParams.perPage) || 25,
    })
    setIsParamsParsedFromURL(true)

    filter?.map((filterItem) => {
      if (filterItem.type === "async") {
        fetchInitialAsyncData(filterItem.name!!, filterItem.asyncFilter!!)
      }
      if (filterItem.type === "checkboxes") {
        setCheckboxes(filterItem.options)
      }
    })
  }, [])

  // Для осуществления асинхронного поиска
  const [asyncOptions, setAsyncOptions] = useState<any>({})

  const getPerPageFetchNumber = () => {
    return typeof paginationData.perPage === "number" ? paginationData.perPage : paginationData.count
  }

  useEffect(() => {
    if (isParamsParsedFromURL) {
      fetchData(paginationData.page + 1, getPerPageFetchNumber())
    }
  }, [refresh, isParamsParsedFromURL])

  useEffect(() => {
    if (paginationData.page * getPerPageFetchNumber() > paginationData.count && isParamsParsedFromURL) {
      fetchData(1, getPerPageFetchNumber(), filterData)
      refreshSearchParams("page", "1")
    }
  }, [paginationData, isParamsParsedFromURL])

  const fetchInitialAsyncData = async (name: string, filteringOptions: TableFilterAsyncSearch) => {
    try {
      const asyncOptionsList = await requestData<any>(filteringOptions.fetchUrl, 1, 25)
      if (Array.isArray(asyncOptionsList.items)) {
        const removedFalsyElementsItems = asyncOptionsList.items.filter(
          (item: any) =>
            item[filteringOptions?.labelNameToDisplayInAutocompleteItems] !== undefined &&
            item[filteringOptions?.labelNameToDisplayInAutocompleteItems] !== null &&
            item[filteringOptions?.keyNameToDisplayInAutocompleteItems] !== undefined &&
            item[filteringOptions?.keyNameToDisplayInAutocompleteItems] !== null,
        )
        setAsyncOptions((prevState: any) => ({
          ...prevState,
          [name]: convertArrayToSelectOptions(
            removedFalsyElementsItems,
            filteringOptions.labelNameToDisplayInAutocompleteItems,
            filteringOptions.keyNameToDisplayInAutocompleteItems,
          ),
        }))
      }
    } catch (e) {
      console.error(e as AxiosError)
    }
  }

  // Функция для поиска данных
  const fetchData = async (page: number, perPage: number, newFilterData?: object) => {
    const tableData = await requestData<any>(fetchUrl, page, perPage, newFilterData || filterData)
    if (tableData) {
      setFilterResults(tableData?.items || tableData)
      if (onUpdate) onUpdate(tableData?.items)
      setPaginationData((prevState) => ({
        ...prevState,
        count: tableData?.total || 0,
        page: tableData?.page - 1 || 0,
        perPage: perPage,
      }))
      if (getTableResults) getTableResults(tableData?.items)
    }
  }

  // Функция для обновления URL параметров
  const fetchAsyncOptions = async (searchString: string, name: string, filteringOptions: TableFilterAsyncSearch) => {
    try {
      const asyncOptionsFetchRequest = await filteringOptions.fetchUrl(
        1,
        50,
        {[filteringOptions.filteringColumnNameOnBackend]: searchString},
        true,
      )
      const asyncOptionsResponse = await asyncOptionsFetchRequest.data

      if (asyncOptionsResponse.status_code === 0) {
        const removedFalsyElementsItems = asyncOptionsResponse.result.items.filter(
          (item: any) =>
            item[filteringOptions?.labelNameToDisplayInAutocompleteItems] !== undefined &&
            item[filteringOptions?.labelNameToDisplayInAutocompleteItems] !== null &&
            item[filteringOptions?.keyNameToDisplayInAutocompleteItems] !== undefined &&
            item[filteringOptions?.keyNameToDisplayInAutocompleteItems] !== null,
        )
        setAsyncOptions((prevState: any) => ({
          ...prevState,
          [name]: convertArrayToSelectOptions(
            removedFalsyElementsItems,
            filteringOptions.labelNameToDisplayInAutocompleteItems,
            filteringOptions.keyNameToDisplayInAutocompleteItems,
          ),
        }))
      } else Alert.displayErrorMessage(`Произошла ошибка при получении ${name}`)
    } catch (e) {
      Alert.displayErrorMessage(`Произошла ошибка при получении ${name}`)
    }
  }

  const refreshSearchParams = (objectKey?: string, objectValue?: string) => {
    if (objectKey && objectValue) {
      // @ts-ignore
      setSearchParams({
        ...filterData,
        ...paginationData,
        page: objectKey !== "page" ? paginationData.page + 1 : undefined,
        [objectKey]: objectValue,
      })
    } else {
      // @ts-ignore
      setSearchParams({
        ...filterData,
        ...paginationData,
      })
    }
  }

  const handleChangePage = (event: any, newPage: number) => {
    setPaginationData((prevState) => ({...prevState, page: newPage}))
    fetchData(newPage + 1, getPerPageFetchNumber())
    refreshSearchParams("page", (newPage + 1).toString())
    if (onChangePage) onChangePage()
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPaginationData((prevState) => ({...prevState, perPage: event.target.value}))
    fetchData(paginationData.page + 1, event.target.value)
    refreshSearchParams("perPage", event.target.value)
  }

  const handleShowFilter = () => {
    setShowFilter(!showFilter)
  }

  // Статичный поиск (Просто TextField)
  const handleSearch = (value: string | Date | boolean | null, name: string) => {
    clearTimeout(filterSearchTimeout.current)

    // Создаем новый объект filterData без пустых значений
    const updatedFilterData = {...filterData}

    // Если значение пустое или состоит только из пробелов, удаляем ключ из объекта
    if (value === null || value === undefined || (typeof value === "string" && value.trim() === "")) {
      delete updatedFilterData[name]
      // Убираем ключ из URL (если он есть)
      searchParams.delete(name)
      setSearchParams(searchParams)
    } else {
      updatedFilterData[name] = value
      // Добавляем значение фильтра в URL
      refreshSearchParams(name, value.toString())
    }
    setFilterData(updatedFilterData)
    getFilterObject && getFilterObject(updatedFilterData)
    filterSearchTimeout.current = setTimeout(() => {
      fetchData(paginationData.page + 1, getPerPageFetchNumber(), updatedFilterData)
    }, 1000)
  }

  const handleAsyncSearch = (searchString: string, name: string, filteringOptions: TableFilterAsyncSearch) => {
    clearTimeout(filterSearchTimeout.current)
    filterSearchTimeout.current = setTimeout(() => {
      fetchAsyncOptions(searchString, name, filteringOptions)
    }, 300)
  }

  // Поиск через Autocomplete
  const handleSearchWithoutLatency = (value: string | Date | boolean | null, name: string) => {
    const data = {...filterData, [name]: value === "" || value === null ? undefined : value}
    setFilterData(data)
    getFilterObject && getFilterObject(data)
    fetchData(paginationData.page + 1, getPerPageFetchNumber(), {
      ...filterData,
      [name]: value === "" || value === null ? undefined : value,
    })

    if (value) {
      // @ts-ignore
      refreshSearchParams(name, value.toString())
    } else {
      searchParams.delete(name)
      setSearchParams(searchParams)
    }
  }

  // Множественный выбор
  const handleSearchWithoutLatencyMultiple = (value: any[], name: string) => {
    const data = {...filterData, [name]: value.length === 0 ? undefined : value.map((item) => item.value)}
    setFilterData(data)
    getFilterObject && getFilterObject(data)
    fetchData(paginationData.page + 1, getPerPageFetchNumber(), {
      ...filterData,
      [name]: value.length === 0 ? undefined : value.map((item) => item.value),
    })

    if (value.length > 0) {
      // @ts-ignore
      refreshSearchParams(
        name,
        // @ts-ignore
        value.map((item) => item.value),
      )
    } else {
      searchParams.delete(name)
      setSearchParams(searchParams)
    }
  }

  const onActionButtonClick = (callBack: ((item: any) => void) | undefined, item: any) => {
    if (callBack) {
      callBack(item)
    }
  }

  return (
    <div className={styles.root}>
      {((filter && filter.length > 0) ||
        (filterByDateTime && filterByDateTime.length) ||
        (filterByDate && filterByDate.length)) && (
        <>
          <div className={styles.filterButton}>
            <Button
              sx={{marginBottom: "0.5rem"}}
              variant={showFilter ? "contained" : "outlined"}
              startIcon={<Icon>{showFilter ? "close" : "filter_list"}</Icon>}
              onClick={handleShowFilter}
            >
              Расширенный поиск
            </Button>
          </div>

          <TableFilter
            filter={filter}
            showFilter={showFilter}
            asyncOptions={asyncOptions}
            filterData={filterData}
            handleSearchWithoutLatency={handleSearchWithoutLatency}
            handleSearchWithoutLatencyMultiple={handleSearchWithoutLatencyMultiple}
            handleCheckboxes={handleCheckboxes}
            checkboxes={checkboxes}
            handleAsyncSearch={handleAsyncSearch}
            handleSearch={handleSearch}
          />

          {showFilter && filterByDateTime && filterByDateTime.length && (
            <div className="py-2">
              <Grid container spacing={2}>
                {filterByDateTime.map((item, index) => (
                  <Grid item sm={12} md={item.size || 12} key={index}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                      <span style={{fontSize: 14}}>{item.title}</span>
                      <MuiDateTimePicker
                        inputFormat="dd.MM.yyyy hh:mm"
                        mask="__.__.____ __:__"
                        clearable
                        onChange={(date: Date | null) => {
                          if (date) handleSearch(date, item.name)
                        }}
                        value={filterData[item.name]?.toString() ?? null}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            fullWidth
                            onChange={(e) => {
                              if (!e.target.value) handleSearch(null, item.name)
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}

          {showFilter && filterByDate && filterByDate.length && (
            <div className="py-2">
              <Grid container spacing={2}>
                {filterByDate.map((item, index) => (
                  <Grid item sm={12} md={item.size || 12} key={index}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                      <span style={{fontSize: 14}}>{item.title}</span>
                      <MuiDatePicker
                        inputFormat="yyyy.MM.dd"
                        mask="____.__.__"
                        clearable
                        onChange={(date: Date | null) => {
                          if (date && !isNaN(date.getFullYear())) {
                            const dateString =
                              `${date.getFullYear()}` +
                              `-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}` +
                              `-${date.getDate()}`

                            handleSearchWithoutLatency(dateString, item.name)
                          }
                        }}
                        value={filterData[item.name]?.toString() ?? null}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            fullWidth
                            inputProps={{...params.inputProps, placeholder: "ГОД.МЕСЯЦ.ДЕНЬ"}}
                            onChange={(e) => {
                              if (!e.target.value) handleSearchWithoutLatency(null, item.name)
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </>
      )}
      {!disablePagination && (
        <TablePagination
          count={paginationData.count}
          page={paginationData.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPage={getPerPageFetchNumber()}
          rowsPerPageOptions={
            paginationItems || paginationData.count > 75 ? [25, 50, 75, paginationData.count] : [25, 50, 75]
          }
          labelRowsPerPage="На страницу"
          labelDisplayedRows={({from, to, count}) => `${from}-${to} из ${count}`}
          component="div"
          sx={{display: "flex"}}
          showFirstButton={true}
          showLastButton={true}
        />
      )}

      <TableContent
        minWidth={minWidth}
        rows={rows}
        items={filterResults}
        paginationData={paginationData}
        onActionButtonClick={onActionButtonClick}
        checkboxSelecting={checkboxSelecting}
        onCheckboxSelected={onCheckboxSelected}
        checkedValidationFunction={checkedValidationFunction}
        onCheckboxSelectAll={onCheckboxSelectAll}
        allCheckedValidationFunction={allCheckedValidationFunction}
      />

      {!disablePagination && (
        <TablePagination
          count={paginationData.count}
          page={paginationData.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPage={getPerPageFetchNumber()}
          rowsPerPageOptions={
            paginationItems || paginationData.count > 75 ? [25, 50, 75, paginationData.count] : [25, 50, 75]
          }
          labelRowsPerPage="На страницу"
          labelDisplayedRows={({from, to, count}) => `${from}-${to} из ${count}`}
          component="div"
          sx={{marginTop: "1rem"}}
          showFirstButton={true}
          showLastButton={true}
        />
      )}
    </div>
  )
}

export default Table
