import s from "./SignedOfferDetailsCustomer.module.scss"
import {IPersonalAccountCustomer} from "../../../../../app/interfaces/personalAccountInterfaces"

interface SignedOfferDetailsCustomerProps {
  customer: IPersonalAccountCustomer
}

export const SignedOfferDetailsCustomer = ({customer}: SignedOfferDetailsCustomerProps) => {
  return (
    <div className={s.container}>
      <div className={s.titleRow}>
        <div className={s.title}>Данные клиента</div>
        <div className={s.idData}>customer_id: {customer.customer.customer_id}</div>
      </div>
      <div className={s.dataRow}>
        <div className={s.dataRowItem}>
          <div className={s.dataRowItemKey}>ФИО</div>
          <div className={s.dataRowItemFiller} />
          <div className={s.dataRowItemValue}>{customer.customer.full_name || "Отсутствует"}</div>
        </div>
        <div className={s.dataRowItem}>
          <div className={s.dataRowItemKey}>ИИН</div>
          <div className={s.dataRowItemFiller} />
          <div className={s.dataRowItemValue}>{customer.customer.iin || "Отсутствует"}</div>
        </div>
      </div>
      <div className={s.dataRow}>
        <div className={s.dataRowItem}>
          <div className={s.dataRowItemKey}>Номер телефона</div>
          <div className={s.dataRowItemFiller} />
          <div className={s.dataRowItemValue}>{customer.customer.phone_number || "Отсутствует"}</div>
        </div>
        <div className={s.dataRowItem}>
          <div className={s.dataRowItemKey}>Еmail</div>
          <div className={s.dataRowItemFiller} />
          <div className={s.dataRowItemValue}>{customer.customer.email || "Отсутствует"}</div>
        </div>
      </div>
    </div>
  )
}
