import React from "react"
import {
  Icon,
  IconButton,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material"
import {NavLink} from "react-router-dom"
import Button from "../Button/Button"
import {TableRowProps} from "../../interfaces/layoutInterfaces"
import s from "./styles.module.scss"

const tableStyles = {
  minWidth: "650px",
  borderRadius: "16px",
  borderColor: "#E9E9E9",
  "& th": {
    padding: "10px",
    color: "#344054",
    maxWidth: "200px",
    fontSize: "12px",
    fontWeight: "400",
    borderColor: "#E9E9E9",
  },
  "& td": {
    padding: "3px 10px",
    borderColor: "#E9E9E9",
  },
}

type Props = {
  minWidth: string | undefined
  rows: TableRowProps[]
  items: any[]
  paginationData: {
    count: number
    page: number
    perPage: number | "all"
  }
  onActionButtonClick: (callBack: ((item: any) => void) | undefined, item: any) => void
  checkboxSelecting?: boolean
  onCheckboxSelected?: (item: any, checked: boolean) => void
  checkedValidationFunction?: (item: any) => boolean
  onCheckboxSelectAll?: (items: any, checked: boolean) => void
  allCheckedValidationFunction?: (items: any) => boolean
}

const TableContent: React.FC<Props> = ({
  minWidth,
  rows,
  items,
  paginationData,
  onActionButtonClick,
  checkboxSelecting,
  onCheckboxSelected,
  checkedValidationFunction,
  onCheckboxSelectAll,
  allCheckedValidationFunction,
}) => {
  const getPerPageFetchNumber = () => {
    return typeof paginationData.perPage === "number" ? paginationData.perPage : paginationData.count
  }

  return (
    <TableContainer component={Paper} sx={{borderRadius: "8px", boxShadow: "none", border: "1px solid #E9E9E9"}}>
      <MuiTable size="small" sx={{...tableStyles, minWidth: minWidth || "650px", tableLayout: "fixed"}}>
        <TableHead>
          <TableRow className={s.tableHeadRow}>
            <TableCell width={45} align="center">
              #
            </TableCell>
            {checkboxSelecting && (
              <TableCell width={50}>
                <Checkbox
                  onChange={(e, checked) => onCheckboxSelectAll?.(items, checked)}
                  size="small"
                  checked={allCheckedValidationFunction?.(items)}
                />
              </TableCell>
            )}
            {rows.map((row, index) => (
              <TableCell align={row.align} key={index} width={row.width} >
                {row.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(items) &&
            items.map((item, itemIndex) => (
              <TableRow hover key={itemIndex}>
                <TableCell align="center">{getPerPageFetchNumber() * paginationData.page + itemIndex + 1}</TableCell>

                {checkboxSelecting && (
                  <TableCell align="center">
                    <Checkbox
                      onChange={(e, checked) => (onCheckboxSelected ? onCheckboxSelected(item, checked) : undefined)}
                      size="small"
                      checked={checkedValidationFunction ? checkedValidationFunction(item) : false}
                    />
                  </TableCell>
                )}

                {rows.map((row, rowIndex) => (
                  <TableCell className="overflow-hidden" align={row.align} key={rowIndex}>
                    {(row.type === "text" || !row.type) &&
                      (typeof row.value === "function" ? row.value(item) : item[row.value ?? ""])}
                    {row.type === "link" && (
                      <>
                        {row.action?.href && (
                          <NavLink
                            to={row.action?.href && row.action?.href(item)}
                            target={row.action?.target}
                            className="no-underline"
                          >
                            <Button
                              disabled={row.action.disabled}
                              variant="outlined"
                              color="primary"
                              size="small"
                              className={row.className}
                              startIcon={row.action?.startIcon ? <Icon>{row.action?.startIcon}</Icon> : undefined}
                            >
                              {row.action?.label}
                            </Button>
                          </NavLink>
                        )}
                        {row.action?.onClickAction && row.action?.label && (
                          <Button
                            disabled={
                              (row.action.onDisabled && row.action.onDisabled(item)) || row.action.disabled || false
                            }
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={row.action?.startIcon && <Icon>{row.action?.startIcon}</Icon>}
                            onClick={() => onActionButtonClick(row.action?.onClickAction, item)}
                          >
                            {typeof row.action.label === "function" ? row.action.label(item) : row.action?.label}
                          </Button>
                        )}
                        {!row.action?.label && (
                          <IconButton
                            color={row.action?.color}
                            disabled={row.action?.disabled}
                            onClick={() => onActionButtonClick(row.action?.onClickAction, item)}
                          >
                            <Icon>{row.action?.startIcon}</Icon>
                          </IconButton>
                        )}
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  )
}

export {TableContent}
