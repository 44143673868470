import React from "react"
import clsx from "clsx"
import styles from "./styles.module.scss"
import {
  Autocomplete as MuiAutocomplete,
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Grid,
  Radio as MuiRadio,
  RadioGroup,
  TextField,
} from "@mui/material"
import FormLabel from "../Form/FormLabel"
import {FilterProps, TableFilterAsyncSearch} from "../../interfaces/layoutInterfaces"

type Props = {
  filter: FilterProps[] | undefined
  showFilter: boolean
  filterData: Record<string, string | boolean | Date | any[] | null | undefined>
  asyncOptions: any
  handleSearchWithoutLatency: (value: string | Date | boolean | null, name: string) => void
  handleSearchWithoutLatencyMultiple: (value: any[], name: string) => void
  handleAsyncSearch: (searchString: string, name: string, filteringOptions: TableFilterAsyncSearch) => void
  handleSearch: (value: string | Date | boolean | null, name: string) => void
  handleCheckboxes: (id: number, value: string, name: boolean) => void
  checkboxes: any
}

const TableFilter: React.FC<Props> = ({
  checkboxes,
  filter,
  showFilter,
  filterData,
  asyncOptions,
  handleCheckboxes,
  handleSearchWithoutLatency,
  handleSearchWithoutLatencyMultiple,
  handleSearch,
  handleAsyncSearch,
}) => {
  return (
    <div>
      {filter && filter.length && showFilter && (
        <div className={clsx(styles.filterContent, {[styles.open]: showFilter})}>
          <Grid container spacing={2}>
            {filter.map((item, index) => (
              <Grid item sm={12} md={item.size || 12} key={index}>
                {(!item.type || item.type === "text") && (
                  <TextField
                    label={item.title}
                    size="small"
                    variant="outlined"
                    value={filterData[item.name!!]}
                    helperText={item.helperText}
                    InputLabelProps={{shrink: !!filterData[item.name!!]}}
                    onChange={(event) => handleSearch(event.target.value, item.name!!)}
                    fullWidth
                  />
                )}
                {item.type === "select" && (
                  <MuiAutocomplete
                    noOptionsText="Совпадении не найдено"
                    fullWidth
                    getOptionLabel={(option) => option[item.optionLabelName ?? "label"]}
                    value={item.options?.find((optionItem) => {
                      if (filterData[item.name!!]) {
                        return optionItem[item.optionValueName ?? "value"].toString() === filterData[item.name!!]
                      }
                    })}
                    onChange={(event, data) =>
                      handleSearchWithoutLatency(data ? data[item.optionValueName ?? "value"] : null, item.name!!)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={item.title}
                        helperText={item.helperText}
                        name={item.name}
                        size="small"
                      />
                    )}
                    options={item.options || []}
                  />
                )}
                {item.type === "multiple_select" && (
                  <MuiAutocomplete
                    multiple
                    noOptionsText="Совпадении не найдено"
                    fullWidth
                    size="small"
                    getOptionLabel={(option) => option.label}
                    value={item.options?.find((optionItem) => {
                      if (filterData[item.name!!]) {
                        return optionItem.value.toString() === filterData[item.name!!]
                      }
                    })}
                    onChange={(event, data) => handleSearchWithoutLatencyMultiple(data ? data : [], item.name!!)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={item.title}
                        helperText={item.helperText}
                        name={item.name}
                        size="small"
                      />
                    )}
                    options={item.options || []}
                  />
                )}
                {item.type === "async" && (
                  <MuiAutocomplete
                    fullWidth
                    noOptionsText="Совпадении не найдено"
                    filterOptions={(x) => x}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.value}>
                          {option.label}
                        </li>
                      )
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={item.title}
                        helperText={item.helperText}
                        name={item.name}
                        onChange={(event) => handleAsyncSearch(event.target.value, item.name!!, item.asyncFilter!!)}
                        size="small"
                      />
                    )}
                    value={item.options?.find((optionItem) => {
                      if (filterData[item.name!!]) {
                        return optionItem[item.optionValueName ?? "value"].toString() === filterData[item.name!!]
                      }
                    })}
                    onChange={(event, data) =>
                      handleSearchWithoutLatency(data ? data[item.optionValueName ?? "value"] : null, item.name!!)
                    }
                    options={asyncOptions[item.name!!] ? asyncOptions[item.name!!] : []}
                  />
                )}
                {item.type === "async_select" && (
                  <MuiAutocomplete
                    noOptionsText="Совпадении не найдено"
                    fullWidth
                    renderInput={(params) => <TextField {...params} label={item.title} name={item.name} size="small" />}
                    options={item.options || []}
                  />
                )}
                {item.type === "checkbox" && (
                  <FormControlLabel
                    control={
                      <MuiCheckbox
                        name={item.name}
                        onChange={(event) =>
                          handleSearchWithoutLatency(
                            event.target.checked ? !item.reversedCheckboxValue : null,
                            item.name!!,
                          )
                        }
                      />
                    }
                    label={item.title}
                  />
                )}
                {item.type === "checkboxes" && (
                  <div className={styles.checkboxesFilter}>
                    <FormLabel>{item.title}</FormLabel>
                    {checkboxes?.map((el: any, index: number) => {
                      return (
                        <FormControlLabel
                          control={
                            <MuiCheckbox
                              name={el.name}
                              checked={el.active}
                              onChange={() => {
                                handleCheckboxes(el.id, el.name, !el.active)
                              }}
                            />
                          }
                          label={el.name}
                        />
                      )
                    })}
                  </div>
                )}
                {item.type === "radio" && (
                  <div>
                    <FormLabel>{item.title}</FormLabel>
                    <RadioGroup name={item.name} defaultValue={item.defaultValue}>
                      <div>
                        {item.radio?.map((radioItem, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <MuiRadio
                                size="small"
                                value={radioItem.value}
                                onChange={(event) => handleSearchWithoutLatency(radioItem.value, item.name!!)}
                              />
                            }
                            label={radioItem.label}
                          />
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                )}
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  )
}

export {TableFilter}
